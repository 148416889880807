<template>
  <div>

    <CCol lg="12">
      <CCard>
        <CCardHeader class="bg-yellow">
          <h3>Upload รายการชำระเงินจากธนาคาร </h3>
        </CCardHeader>
        <form id="formUploadTransaction" v-on:submit.prevent="sendFile" method="POST">
          <CCardBody>

            <CRow>
              <CCol lg="6">
                <div class="dropbox">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    File:
                  </label>
                  <input type="file" class="form-control" name="file" required>
                </div>
              </CCol>
              <CCol lg="6">
                <div class="relative w-full mb-3" v-if="userData">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    Secret Code:
                  </label>
                  <input type="text" name="secretCode" class="form-control" aria-label="Username"
                    aria-describedby="basic-addon1" required />
                </div>
              </CCol>

            </CRow>


          </CCardBody>
          <CCardFooter class="text-right">
            <button class="btn btn-yellow" :disabled="disableBtn">
              <span v-if="disableBtn == false">ส่ง</span><span v-else>Sending...</span>
            </button>
          </CCardFooter>
        </form>
      </CCard>

      <!--MODAL SECTION---------------------------------------------------------------------------->
      <form id="formCreateCriteria" v-on:submit.prevent="addCriteria" method="POST">
        <CModal :show.sync="modalAddCriteria" :no-close-on-backdrop="true" :centered="true" title="Modal title 2"
          size="lg" color="dark">
          <CRow>
            <CCol lg="6" v-if="userData">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  รหัสโรงเรียน
                </label>
                <input type="text" name="schoolCode" class="form-control" readonly :value="userData.user_address" />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="userData">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  รหัสเกณฑ์
                </label>
                <input type="text" name="criteriaNo" class="form-control" aria-label="Username"
                  aria-describedby="basic-addon1" v-model="criteriaRow" readonly required />
              </div>
            </CCol>
            <CCol lg="6" v-if="userData">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  ชื่อวิชา/ สถานที่พบเจอ
                </label>
                <input type="text" name="criteriaName" class="form-control" required />
              </div>
            </CCol>
            <CCol lg="6" v-if="userData">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  คะแนน
                </label>
                <input type="number" name="criteriaScore" class="form-control" required />
              </div>
            </CCol>
          </CRow>
          <template #header>
            <h6 class="modal-title">เพิ่มเกณฑ์คะแนน</h6>
            <CButtonClose @click="modalAddCriteria = false" class="text-white" />
          </template>
          <template #footer>
            <button type="submit" class="btn btn-yellow">Save</button>
            <button class="btn btn-black" @click="modalAddCriteria = false">
              Discard
            </button>
          </template>
        </CModal>
      </form>
    </CCol>
  </div>
</template>

<script>

import TblPendingSummary from "./components/TblPendingSummary";
import TblInvoiceBySchool from "./components/TblInvoiceBySchool";
import TblInvoiceByDate from "./components/TblInvoiceByDate";
import CardCreateQRbyLevel from "./components/CardCreateQRbyLevel";

export default {
  name: "Dashboard",
  components: {

    TblPendingSummary,
    TblInvoiceBySchool,
    TblInvoiceByDate,
    CardCreateQRbyLevel,
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
      disableBtn: false
    };
  },
  methods: {
    sendFile(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      this.disableBtn = true;
      this.axios
        .post(this.$hostUrl + "php_action/import_ExcelTransaction.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.action) {
            this.responseContent = "บันทึกสำเร็จ";
            location.reload();
          } else {
            alert(response.data.error)
            this.disableBtn = false;
          }
        });
    }
  },
  mounted() {
    //console.log(this.userData);
  },
};
</script>
